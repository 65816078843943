
<template>
  <div>
    <!-- #region EMPRESA -->

    <!-- #region INFORMACION DE IDENTIFICACION  -->
    <div class="content-identification-information">
      <p class="text-identification-information">
        <v-skeleton-loader width="30%" type="text"></v-skeleton-loader>
      </p>
      <v-container fluid>
        <v-row>
          <!-- #region LABELS AND TEXTS-FIELDS ( NOMBRE PUBLICO, CORREO ELECTRONICO, COMPONENTE GLOBAL DE TELEFONO, PAIS, ESTADO, CIUDAD, CODIGO POSTAL, DIRECCION , DIRECCION OPCIONAL )  -->

          <!-- #region NOMBRE PUBLICO DE LA EMPRESA  -->
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion NOMBRE PUBLICO DE LA EMPRESA -->

          <!-- #region CORREO ELECTRONICO  -->
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion CORREO ELECTRONICO -->

          <!-- #region COMPONENTE GENERAL DE TELEFONO -->
          <v-col cols="12" sm="12" md="12" lg="6" xl="6">
            <skeleton-phone-component-global />
          </v-col>
          <!-- #endregion COMPONENTE GENERAL DE TELEFONO -->

          <!-- #region PAIS -->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion PAIS -->

          <!-- #region ESTADO -->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion ESTADO -->

          <!-- #region CIUDAD -->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion CIUDAD -->

          <!-- #region CODIGO POSTAL -->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion CODIGO POSTAL -->

          <!-- #region DIRECCION -->
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion DIRECCION -->

          <!-- #region DIRECCION OPCIONAL -->
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion  DIRECCION OPCIONAL-->

          <!-- #endregion TEXTS-FIELDS ( NOMBRE PUBLICO, CORREO ELECTRONICO, COMPONENTE GLOBAL DE TELEFONO, PAIS, ESTADO, CIUDAD, CODIGO POSTAL, DIRECCION , DIRECCION OPCIONAL) -->
        </v-row>
      </v-container>
      <v-divider class="divider-global mt-9"></v-divider>
    </div>
    <!-- #endregion INFORMACION DE IDENTIFICACION -->

    <!-- #region INFORMACION DE ENCARGADO  -->
    <div class="content-identification-information">
      <p class="text-identification-information">
        <v-skeleton-loader width="30%" type="text"></v-skeleton-loader>
      </p>
      <v-container fluid>
        <v-row>
          <!-- #region LABELS AND TEXTS-FIELDS(NOMBRE, CORREO ELECTRONICO, COMPONENTE GLOBAL DE TELEFONO) -->
          <!-- #region NOMBRE -->
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion NOMBRE -->

          <!-- #region CORREO ELECTRONICO -->
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion CORREO ELECTRONICO -->

          <!-- #region COMPONENTE GLOBAL DE TELEFONO -->
          <v-col cols="12" sm="12" md="12" lg="6" xl="6">
            <skeleton-phone-component-global />
          </v-col>
          <!-- #endregion COMPONENTE GLOBAL DE TELEFONO -->

          <!-- #endregion LABELS AND TEXTS-FIELDS(NOMBRE, CORREO ELECTRONICO, COMPONENTE GLOBAL DE TELEFONO)-->
        </v-row>
      </v-container>
      <v-divider class="divider-global mt-9"></v-divider>
    </div>
    <!-- #endregion INFORMACION DE ENCARGADO -->

    <!-- #region INFORMACION FISCAL  -->
    <div class="content-identification-information">
      <p class="text-identification-information">
        <v-skeleton-loader width="30%" type="text"></v-skeleton-loader>
      </p>
      <v-container fluid>
        <v-row>
          <!-- #region LABELS AND TEXTS-FIELDS(NOMBRE, CORREO ELECTRONICO, REGISTRO FISCAL, PAIS , ESTADO, CIUDAD, CODIGO POSTAL, DIRECCION, DIRECCION OPCIONAL) -->
          <!-- #region NOMBRE -->
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion NOMBRE -->

          <!-- #region CORREO ELECTRONICO -->
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion CORREO ELECTRONICO -->

          <!-- #region REGISTRO FISCAL -->
          <v-col cols="12" sm="12" md="12" lg="6" xl="6">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion REGISTRO FISCAL -->

          <!-- #region espacio libre -->
          <!-- <v-col cols="12" sm="3" md="3" lg="3" xl="3"> </v-col> -->
          <!-- #endregion espacio libre  -->

          <!-- #region PAIS -->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion PAIS -->

          <!-- #region ESTADO -->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion ESTADO -->

          <!-- #region CIUDAD -->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion CIUDAD -->

          <!-- #region CODIGO POSTAL -->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion CODIGO POSTAL -->

          <!-- #region DIRECCION -->
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion DIRECCION -->

          <!-- #region DIRECCION OPCIONAL-->
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <!-- #endregion DIRECCION OPCIONAL-->

          <!-- #endregion LABELS AND TEXTS-FIELDS(NOMBRE, CORREO ELECTRONICO, REGISTRO FISCAL, PAIS , ESTADO, CIUDAD, CODIGO POSTAL, DIRECCION, DIRECCION OPCIONAL)  -->
        </v-row>
      </v-container>

      <v-divider class="divider-global mt-9"></v-divider>
    </div>
    <!-- #endregion INFORMACION FISCAL-->

    <!-- #endregion EMPRESA -->
    <!-- #region ACCIONES DE BOTONES-->
    <div class="content-btns-accions">
      <div class="content-btn-second">
        <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
      </div>

      <v-spacer></v-spacer>
      <div class="content-btn-primary">
        <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
      </div>
    </div>
    <!-- #endregion -->
  </div>
</template>

<script>
export default {
  name: "ProviderDetail",
  props: {
    iTab: Number,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.content-identification-information {
  margin-bottom: 25px;
  padding: 0px 0px 0px 12px;
}

.text-identification-information {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 16px;
  letter-spacing: 3px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}
.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
.content-btns-accions {
  display: flex;
  padding: 0px 0px 0px 12px;
  margin-top: 25px;
  margin-bottom: 5px;
  width: 100% !important;
}
.content-btn-primary {
  width: 200px;
}
.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

.text-identification {
  margin-bottom: 0px;
  color: var(--primary-color-text);
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
}
/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}
/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>